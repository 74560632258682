import React from 'react';
import '../css/Services.css';

const Services = () => {
  return (
    <section className="services" id="services">
      <h2>Our Services</h2>
      <div className="service-list">
        <div className="service">
          <h3>Web Development</h3>
          <p>Building responsive and modern websites tailored to your needs.</p>
        </div>
        <div className="service">
          <h3>Mobile Apps</h3>
          <p>Creating user-friendly mobile applications for Android and iOS.</p>
        </div>
        <div className="service">
          <h3>AI Solutions</h3>
          <p>Implementing AI to automate processes and improve efficiency.</p>
        </div>
        <div className="service">
          <h3>Cloud Services</h3>
          <p>Offering scalable cloud solutions to grow with your business.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
