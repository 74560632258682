import React from 'react';
import '../css/Description.css';

const Description = () => {
  return (
    <section className="description" id="about">
      <h2>About Texabyte</h2>
      <p>
        At Texabyte, we specialize in delivering cutting-edge technology solutions to businesses of all sizes.
        From web development and mobile apps to AI-powered tools and cloud services, we have the expertise to
        transform your ideas into reality. Our team is dedicated to staying ahead of industry trends, ensuring
        that our clients always receive the most innovative solutions available.
      </p>
      <p>
        We believe in building lasting relationships with our clients, offering personalized service and support
        at every step of the way. Whether you're a startup looking to make your mark or an established enterprise
        seeking to innovate, Texabyte is your trusted partner in technology.
      </p>
    </section>
  );
};

export default Description;
