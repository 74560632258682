import React from 'react';
import '../css/CTA.css';

const CTA = () => {
  return (
    <section className="cta">
      <h2>Ready to Elevate Your Business?</h2>
      <p>Contact us today to learn how Texabyte can help you achieve your goals with cutting-edge technology solutions.</p>
      <button className="cta-button">Contact Us</button>
    </section>
  );
};

export default CTA;
