import React from 'react';
import '../css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <p>&copy; 2024 Texabyte. All rights reserved.</p>
      {/* <p>Follow us on <a href="#">LinkedIn</a>, <a href="#">Twitter</a>, <a href="#">Facebook</a>.</p> */}
      <p>Contact us: <a href="mailto:josh@texabyte.in">josh@texabyte.in</a></p>
    </footer>
  );
};

export default Footer;
