import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Description from './components/Description';
import CTA from './components/CTA';
import Footer from './components/Footer';
import './css/App.css';

const App = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Services />
      <Description />
      <CTA />
      <Footer />
    </div>
  );
};

export default App;
