import React from 'react';
import '../css/Hero.css';

const Hero = () => {
    return (
        <section className="hero" id="home">
            <div className="hero-bg"></div>
            <h1>Welcome to Texabyte</h1>
            <p>Innovative Tech Solutions for a Modern World</p>
            <button className="get-started">Get Started</button>
        </section>
    );
};

export default Hero;
